import React, { useState } from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../layouts/secondary-landing";
import Seo from "../components/seo/seo";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import Hero from "../components/hero/hero";
import getHeroImgVariables from "../helpers/getHeroImgVariables";

// Font Awesome icons
import Icon from "../components/custom-widgets/icon";
import MobileAppDownloadBtn from "../components/mobile-online-banking/mobile-app-download-btn";

const Maintenance = () => {
  const [query, setQuery] = useState("");
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(relativePath: { eq: "hero/maintenance/hero-maintenance-01-2500.jpg" }) {
        ...heroImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/maintenance/hero-maintenance-01-1920.jpg" }) {
        ...heroImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/maintenance/hero-maintenance-01-1200.jpg" }) {
        ...heroImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/maintenance/hero-maintenance-01-992.jpg" }) {
        ...heroImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/maintenance/hero-maintenance-01-768.jpg" }) {
        ...heroImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/maintenance/hero-maintenance-01-600.jpg" }) {
        ...heroImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/maintenance/hero-maintenance-01-375.jpg" }) {
        ...heroImageFragmentXXS
      }
    }
  `);

  const heroData = {
    id: "maintenance-hero",
    ...getHeroImgVariables(imgData, ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"]),
    altText: "WaFd Walt holding Under Maintenance board"
  };

  const title = "Maintenance",
    description = "WaFd Bank Maintenance";
  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/maintenance"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      url: "/maintenance",
      title: "Maintenance"
    }
  ];

  const helpSectionData = [
    {
      url: "/",
      icon: "home",
      content: [
        <Link className="stretched-link" to="/"></Link>,
        <p className="font-weight-bold">WaFd Bank Home</p>,
        <p>Proudly serving our customers since 1917</p>
      ]
    },
    {
      icon: "map-marker-alt",
      content: [
        <Link className="stretched-link" to="/locations"></Link>,
        <p className="font-weight-bold">Find Local Branch</p>,
        <p>Serving 9 states and access to 33,000 fee-free ATMs</p>
      ]
    },
    {
      icon: "mobile-alt",
      content: [
        <p>
          <a href="tel:800-324-9375" className="text-black font-weight-bold text-decoration-none">
            800-324-9375
          </a>
        </p>,
        <p>
          <Link to="/about-us/contact-us">
            Customer Service is standing by to help with any banking need or question
          </Link>
        </p>,
        <p>
          For international callers, please use <a href="tel:206-624-7930">206-624-7930</a>
        </p>
      ]
    }
  ];

  return (
    <SecondaryLanding>
      <Seo {...seoData} />
      <Hero {...heroData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        {/* Maintenance Message Placeholder */}
        <div id="maintenance-message"></div>
      </section>
      <div className="container">
        <h5 className="text-success">Get the WaFd Bank mobile app</h5>
        <div className="row mt-4">
          <div className="col-auto mb-3 mb-sm-0 mr-md-4">
            <MobileAppDownloadBtn
              idName="google-play-store"
              storeBtn="google"
              url="https://play.google.com/store/apps/details?id=com.washingtonfederal.mobilebanking&amp;hl=en_US"
            />
            <p className="text-secondary text-sm">For Android devices</p>
          </div>
          <div className="col-auto mb-3 mb-sm-0">
            <MobileAppDownloadBtn
              idName="apple-store"
              storeBtn="apple"
              url="https://apps.apple.com/us/app/wafd-bank/id1054549841"
            />
            <p className="text-secondary text-sm">For Apple devices</p>
          </div>
        </div>
        <div className="d-none d-md-block">
          <StaticImage
            src="../images/wafd-mobile-app-qr-code.png"
            alt="WaFd App QR Code"
            placeholder="blurred"
            quality="100"
          />
          <p className="text-secondary text-sm">Scan to download</p>
        </div>
      </div>
      <section className="bg-light border-y mt-5">
        <div className="container text-center">
          <h1>Can we help you find something?</h1>
          <form
            className="offset-sm-2 col-sm-8 offset-md-3 col-md-6 offset-lg-4 col-lg-4"
            onSubmit={(event) => {
              event.preventDefault();
              navigate(`/customer-service/search?addsearch=${query}`);
              setQuery("");
            }}
          >
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="How can we help?"
                aria-label="site search text"
                aria-describedby="search-input"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
              />
              <Link
                className="btn-primary"
                type="button"
                to={`/customer-service/search?addsearch=${query}`}
                id="search-button"
              >
                <Icon name="search" class="fa m-2" />
                <span className="sr-only">Search</span>
              </Link>
            </div>
          </form>
        </div>
      </section>
      <section className="container">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
          {helpSectionData.map((data) => {
            return (
              <div className={`col mb-5 ${data.colClass}`}>
                <div className="row">
                  <div className="col-3 text-center">
                    <Icon class="fa-3x text-black" name={data.icon} />
                  </div>

                  <div className="col">
                    {data.content.map((content) => {
                      return content;
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default Maintenance;
